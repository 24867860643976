<script>
import EventBus from '@/event-bus'
import customTranslations from '@/mixins/customTranslations'

const TAB_LABELS = {
    CalendarView: 'history.view-switcher.calendar-label',
    ListView: 'history.view-switcher.list-label',
    NowView: 'history.view-switcher.current',
    OngoingEvents: 'summary.events_list.ongoing',
    PlannedEvents: 'summary.events_list.planned'
}

export default {
    name: 'Tabs',
    mixins: [customTranslations],
    props: {
        compact: {
            type: Boolean,
            default: false
        },
        defaultTabIndex: {
            type: Number,
            default: null
        },
        tabs: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            currentTabIndex: 0,
            registeredTabs: {}
        }
    },
    computed: {
        currentTab () {
            return this.registeredTabs[this.currentTabIndex]
        },
        elementId () {
            return this.compact ? 'tabs-compact' : 'tabs'
        },
        hasDefaultTabIndex () {
            return this.defaultTabIndex !== null
        }
    },
    beforeMount () {
        this.setInitialActiveTabIndex()
    },
    mounted () {
        this.showCurrentTab()
        EventBus.$on('switch-to-calendar', this.switchToCalendar)
    },
    methods: {
        getTabLabel (key) {
            return TAB_LABELS[key]
                ? this.$t(this.i18nKey(TAB_LABELS[key]))
                : key
        },
        getTabTestID (tab) {
            const tabSlug = this.getTabLabel(tab.component)
                .toLowerCase()
                .replace(' ', '-')
            return `tab-${tabSlug || index}`
        },
        registerTabComponent (component) {
            this.registeredTabs[component.index] = component
        },
        showCurrentTab () {
            this.currentTab?.show()
        },
        hideCurrentTab () {
            this.currentTab?.hide()
        },
        switchTab (index) {
            this.hideCurrentTab()
            this.currentTabIndex = index
            this.showCurrentTab()
            EventBus.$emit('historyTabSwitch')
            EventBus.$emit('new-content-loaded')
            this.$emit('tab-change')
            if (!this.hasDefaultTabIndex) {
                $cookies.set(`${this.elementId}-active-tab`, index)
            }
        },
        switchToCalendar () {
            const calendarIndex = this.tabs
                .findIndex((tab) => tab.component === 'CalendarView')

            if (calendarIndex === -1) return

            this.switchTab(calendarIndex)
            const tabsEl = document.getElementById(this.elementId)
            window.scrollTo(0, tabsEl.offsetTop)
        },
        setInitialActiveTabIndex () {
            const activeTabCookie = $cookies.get(`${this.elementId}-active-tab`)
            if (this.hasDefaultTabIndex) {
                this.currentTabIndex = this.defaultTabIndex >= this.tabs.length
                    ? 0
                    : this.defaultTabIndex
            } else if (activeTabCookie === null ||
                parseInt(activeTabCookie) >= this.tabs.length) {
                this.currentTabIndex = 0
            } else {
                this.currentTabIndex =
                    parseInt($cookies.get(`${this.elementId}-active-tab`))
            }
        }
    },
    provide () {
        return {
            register: this.registerTabComponent
        }
    },
    emits: ['tab-change'],
    expose: ['switchTab', 'compact', 'elementId']
}
</script>

<template>
    <div :id="elementId" class="tabs" :class="{ 'tabs--compact': compact }">
        <div class="tabs__header">
            <ul
                class="tabs__header__items"
                :class="{ 'tabs__header__items--more-than-two': tabs.length > 2 }"
            >
                <li v-for="(tab, index) in tabs"
                    :key="index"
                >
                    <a
                        href="#"
                        @click="switchTab(index)"
                        class="tabs__header__items__item"
                        :class="{
                            'tabs__header__items__item--active': index === currentTabIndex,
                            'tabs__header__items__item--disabled': tab.config?.disabled
                        }"
                        :data-test-id="getTabTestID(tab, index)"
                        v-on:click.prevent
                    >
                        <span class="item">{{ getTabLabel(tab.component) }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tabs__content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tabs {
    width: 100%;
    position: relative;

    &__header__items {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        padding-bottom: 20px;
        padding-inline-start: 0px;
        row-gap: 20px;

        a:hover, a:visited, a:link, a:active {
            text-decoration: none;
        }

        li {
            list-style: none;
        }

        @include breakpoint($sm) {
            padding-bottom: 0px;
        }

        &--more-than-two {
            justify-content: space-between;

            @include breakpoint($sm) {
                justify-content: center;
            }

            .tabs__header__items__item {
                padding: 0.5rem 0.25rem;

                @include breakpoint($sm) {
                    padding: 0.5rem 1rem;
                }
            }
        }

        &__item {
            padding: 8px 16px;
            cursor: pointer;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;
            transition: all 0.3s;
            white-space: nowrap;

            @include breakpoint($sm) {
                padding: 16px 32px;
            }

            .item {
                text-transform: uppercase;
                font-size: 0.8125rem;
                color: $textColor;
                font-weight: 600;
                letter-spacing: 2px;
            }

            &--active {
                border-bottom: 2px solid $blue;

                .item {
                    color: $blue;
                }
            }

            &--disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            &:hover:not(.tabs__header__items__item--active) {
                border-bottom: 2px solid $textColor;
            }
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &--compact {
        .tabs__header {
            width: 100%;
            box-sizing: border-box;
            background-color: $backgroundColorLight;
            border-bottom: 1px solid $borderColor;
            padding: 0 1.25rem;
            position: relative;

            @include breakpoint($sm) {
                padding: 0 3.5rem;
            }

            &__items {
                width: 100%;
                height: 3rem;
                margin: 0;
                padding: 0;
                justify-content: space-around;

                @include breakpoint($sm) {
                    padding: 0 0.5rem;
                }

                li {
                    flex-grow: 1;

                    @include breakpoint($sm) {
                        flex-grow: 0;
                    }
                }

                &__item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                    height: 100%;

                    @include breakpoint($sm) {
                        min-width: 8rem;
                    }

                    &--active {
                        border-bottom: 3px solid $blue;
                    }

                    &:hover,
                    &--active {
                        padding-top: 9px;

                        @include breakpoint($sm) {
                            padding-top: 16.5px;
                        }
                    }

                    &:hover:not(.tabs__header__items__item--active) {
                        border-bottom: 3px solid $textColor;
                    }
                }
            }
        }
    }
}
</style>
