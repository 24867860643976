<template>
    <div class="events-list" v-if="anyEventExists">
        <Tabs
            :tabs="tabs"
            :default-tab-index="defaultView"
            ref="tabs"
            class="events-list__tabs"
            compact
            @tabChange="onTabChange"
        >
            <Tab :index="0" tab-key="OngoingEvents">
                <OngoingEvents :incidents="incidents" :maintenances="maintenances" />
            </Tab>
            <Tab :index="1" tab-key="PlannedEvents">
                <PlannedEvents :maintenances-planned="maintenancesPlanned" />
            </Tab>
        </Tabs>
    </div>
    <div v-else>
        <div class="no-events">
            <span class="no-events__text">
                {{ $t('summary.notifications.no-events') }}
            </span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OngoingEvents
    from '@/views/hub/components/events/events-list/OngoingEvents.vue'
import PlannedEvents
    from '@/views/hub/components/events/events-list/PlannedEvents.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'

export default {
    name: 'EventsList',
    components: {
        PlannedEvents,
        OngoingEvents,
        Tab,
        Tabs
    },
    data() {
        return {
            manuallySelected: false
        }
    },
    computed: {
        ...mapGetters({
            incidents: 'incidents/current',
            maintenances: 'maintenances/current',
            maintenancesPlanned: 'maintenances/future',
            offline: 'offlineAttr'
        }),
        tabs () {
            return [
                {
                    component: 'OngoingEvents',
                    config: {
                        disabled: this.offline || !this.existsOngoing
                    }
                },
                {
                    component: 'PlannedEvents',
                    config: {
                        disabled: this.offline || !this.existsPlanned
                    }
                }
            ]
        },
        anyEventExists() {
            return this.maintenances.length +
                this.incidents.length +
                this.maintenancesPlanned.length > 0
        },
        defaultView() {
            return this.existsOngoing ? 0 : 1
        },
        existsOngoing() {
            return this.maintenances.length + this.incidents.length > 0
        },
        existsPlanned() {
            return this.maintenancesPlanned.length > 0
        },
    },
    methods: {
        onTabChange () {
            this.manuallySelected = true
        }
    },
    watch: {
        existsOngoing() {
            if (this.manuallySelected) {
                return
            }

            this.$refs.tabs?.switchTab(this.defaultView)
        }
    }
}
</script>
<style lang="scss" scoped>
.events-list {
    --event-min-height-mobile: 72.5px;
    --event-min-height-desktop: 84.5px;

    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #FFFFFF;
    overflow: hidden;
    font-family: Open Sans,ITC Franklin Gothic,Helvetica Neue,helvetica,sans-serif;

    :deep(.events) {
        &.events--ongoing,
        *.events--planned__list {
            margin-left: 0.875rem;

            @include breakpoint($sm) {
                margin-left: 1.5rem;
            }
        }

        &.events--ongoing {
            max-height: calc(var(--event-min-height-mobile) * 4);
            overflow-y: auto;

            @include breakpoint($sm) {
                max-height: calc(var(--event-min-height-desktop) * 4);
            }
        }

        .item {
            border-bottom: 1px solid $borderColor;
            padding: 0.875rem 0.875rem 0.875rem 0;
            margin: 0;
            align-items: flex-start;

            @include breakpoint($sm) {
                padding: 1.25rem 1.5rem 1.25rem 0;
            }

            &__title {
                font-size: 0.9375rem;
            }

            .icon {
                width: 1.4375rem;
            }
        }
    }
}

.no-events {
    border-radius: 6px;
    background-color: #F4F7FD;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        font-size: 1.0625rem;
        font-weight: 600;
        line-height: 1.4375rem;
        color: $textColor
    }
}
</style>
