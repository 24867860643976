<template>
    <div class="group">
        <span class="name">{{ group.group_name }}</span>
        <div class="services">
            <div
                v-for="(status, i) in group.services"
                :key="status.service_id"
                class="service"
            >
                <a
                    class="link"
                    :class="{ 'link--inactive': view !== views.LIST }"
                    v-tooltip="{
                        content: serviceName(status), theme: 'info-tooltip'
                    }"
                    v-smooth-scroll="{ duration: 1000 }"
                    :href="view === views.LIST ? `#service-${status.service_id}` : ''"
                    :aria-label="getAriaLabel(status)"
                >
                    <StatusDot class="service-tooltip" :status="status.status" />
                </a>
                <div class="service--name">
                    {{serviceName(status)}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StatusDot from '@/components/StatusDot.vue';
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import { mapGetters } from 'vuex';

const VIEWS = {
    LIST: 0,
    CALENDAR: 1
}

export default {
    components: {
        StatusDot
    },
    mixins: [connectedHubsGrid],
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            views: VIEWS
        }
    },
    computed: {
        ...mapGetters({
            view: 'history/view'
        })
    },
    methods: {
        getAriaLabel(status) {
            var labelKey;
            if (status.incident_ids) {
                labelKey = 'summary.details.statuses.incident-on'
            } else if (status.maintenance_ids) {
                labelKey = 'summary.details.statuses.under-maintenance'
            } else {
                labelKey = 'summary.details.statuses.no-events'
            }
            return this.$t(labelKey, { name: this.serviceName(status) })
        }
    }
}
</script>
<style lang="scss" scoped>
.group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;

    .name {
        text-align: left;
        color: $textColor;
        font-size: 1.0rem;
        font-weight: 700;
        margin-left: 8px;
        padding-bottom: 1rem;
    }

    .service {
        display: flex;
        align-items: center;
        flex-direction: rows;
        padding: 0.25rem 0;
    }

    .service--name {
        padding-left: 0.25rem;
        font-size: 0.8125rem;
        word-break: break-word;
    }

    .services {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;

        .link {
            text-decoration: none;
            color: #fff;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 3px;

            &--inactive {
                cursor: default
            }
        }
    }
}
</style>
