<template>
    <div
        v-if="groups.length"
        class="statuses"
    >
        <div
            v-for="(column, i) in columns"
            :key="i"
            class="statuses__column"
        >
            <Group
                v-for="(group, index) in column"
                :key="group.group_id"
                :group="group"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Group from './GroupBlock.vue'

export default {
    components: {
        Group
    },
    computed: {
        ...mapGetters({
            config: 'config',
            groups: 'statuses/forGroups'
        }),
        columns() {
            let column = 0
            let columns = [[], [], []]
            let columnSize = Math.ceil(this.groups.length / 3)
            if (columnSize === 0) { return columns }

            const groups = [...this.groups]
            groups.forEach((group, i) => {
                column = Math.floor(i / columnSize)
                columns[column].push(group)
            })
            return columns
        }
    }
}
</script>

<style lang="scss" scoped>
.statuses {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint($md) {
        flex-direction: row;
    }

    &__column {
        width: 100%;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}
</style>
