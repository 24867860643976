import CalendarView from '@/views/hub/components/history/Calendar.vue'
import Counters from '@/views/hub/components/summary/details/Counters.vue'
import Description from '@/views/hub/components/summary/details/Description.vue'
import Events from '@/views/hub/components/Events.vue'
import EventsList from '@/views/hub/components/events/EventsList.vue'
import GroupBlocks from '@/views/hub/components/summary/details/groupBlocks/Component.vue'
import ListView from '@/views/hub/components/history/List.vue'
import Metrics from '@/views/hub/components/history/Metrics.vue'
import NoEventsBox from '@/views/hub/components/events/NoEventsBox.vue'
import NowView from '@/views/hub/components/now/NowView.vue'
import Statuses from '@/views/hub/components/summary/details/Statuses.vue'
import Uptime from '@/views/hub/components/history/Uptime.vue'

export default {
    components: {
        CalendarView,
        Counters,
        Description,
        Events,
        EventsList,
        GroupBlocks,
        ListView,
        Metrics,
        NoEventsBox,
        NowView,
        Statuses,
        Uptime
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    }
}
