<template>
    <div class="rows">
        <div
            class="row__element"
            v-for="childBlock in block.children"
        >
            <component
                :is=childBlock.component
                :block=childBlock
                v-bind="childBlock.config"
            >
            </component>
        </div>
    </div>
</template>

<script>
import layout from '@/mixins/layout'

export default {
    mixins: [layout]
}
</script>

<style lang="scss" scoped>
.rows {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;

    .row__element {
        flex: 1 1 0;
    }
}
</style>

