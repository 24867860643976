<template>
    <div class="layout-tabs" :class="{ 'layout-tabs--no-header': !showHeader }">
        <div v-if="showHeader" class="layout-tabs__header">
            <h2 class="layout-tabs__header__text">{{ $t(i18nKey('history.list.label')) }}</h2>
        </div>
        <Tabs :default-tab-index="defaultTabIndex" :tabs="tabs" ref="tabs">
            <Tab
                v-for="(tab, index) in tabs"
                :key="index"
                :index="index"
                :tab-key="tab.component"
            >
                <component :is="tab.component" v-bind="tab.config" />
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import customTranslations from '@/mixins/customTranslations'
import layout from '@/mixins/layout'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'

export default {
    name: 'LayoutTabs',
    mixins: [
        customTranslations,
        layout
    ],
    components: {
        Tab,
        Tabs
    },
    props: {
        defaultTabIndex: {
            type: Number,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        showHeader() {
            return [0, 1].includes(this.config.view_type)
        },
        tabs () {
            return this.block?.children || []
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-tabs {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;

    @include breakpoint($lg) {
        padding: 0 calc((100% - #{$screen-lg-min}) / 2 + 48px);
    }

    &__header {
        text-align: left;

        &__text {
            color: $textColor2;
            font-size: 1.75rem;
            font-weight: 400;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
        }
    }
}
</style>
