<template>
    <div v-if="isChartDataReady" class="uptime">
        <h2 class="uptime__header">
            {{ $t('history.uptime.label') }}
        </h2>
        <div class="bar">
            <div class="bar__ranges">
                <Button
                    v-for="(rangeName, idx) in uptimeRanges"
                    v-bind:key="idx"
                    @click="setChartRange(rangeName)"
                    :disabled="offline"
                    class="range-button"
                    :class="{ 'button--selected': range.name == rangeName}"
                >
                    {{ $t(`history.uptime.${rangeName}`) }}
                </Button>
            </div>
            <div class="bar__services">
                <Select v-model="selectedService" :disabled="offline" :options="services" label-key="name" class="services-select" :placeholder="$t('history.uptime.select-placeholder')" />
            </div>
        </div>
        <div class="charts">
            <Chart
                class="charts__chart"
                :range="chartData.range"
                :labels="chartData.labels"
                :data="chartData.values"
            />
            <div class="charts__average">
                <span
                    v-if="chart.average !== undefined"
                    class="value"
                    :style="{ fontSize: averageChartFontSize }"
                >
                    {{ chart.average }} %
                </span>
                <span class="label">{{ $t('history.uptime.avg-uptime') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import Chart from '@/components/Chart.vue'
import Select from '@/components/Select.vue'
import Button from '@/components/Button.vue'
import { mapActions, mapGetters } from 'vuex';
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import progress from '@/mixins/progress'
import { captureException } from '@sentry/vue'

const DEFAULT_UPTIME_RANGES = ['day', 'week', 'month', 'year']

export default {
    name: 'Uptime',
    mixins: [connectedHubsGrid, progress],
    components: {
        Chart,
        Select,
        Button
    },
    data() {
        return {
            chartData: {},
            isChartDataReady: false
        }
    },
    computed: {
        ...mapGetters({
            config: 'config',
            servicesList: 'uptime/services',
            data: 'uptime/data',
            range: 'uptime/range',
            service: 'uptime/service',
            offline: 'offlineAttr'
        }),
        chart() {
            return this.service ?
                this.data(this.service.id) :
                {}
        },
        selectedService: {
            get() {
                return this.service
            },
            async set(service) {
                try {
                    this.setService(service)
                    await this.progress(() => this.fetchChartData())
                    this.setChartData()
                } catch (e) {
                    captureException(e)
                    this.pushMessage(this.$t('communicates.errors.fetch-history'))
                }
            }
        },
        services() {
            return this.servicesList.map(service => {
                return {
                    id: service.id,
                    name: this.serviceName({
                        service_id: service.id,
                        service_name: service.name
                    })
                }
            })
        },
        averageChartFontSize() {
            const defaultFontSize = Number(window
                .getComputedStyle(document.body)
                .getPropertyValue('font-size')
                .match(/\d+/)[0]
            )
            return `${defaultFontSize >= 20 ? '1.75' : '2.125'}rem`
        },
        uptimeRanges () {
            return this.config.uptime_ranges || DEFAULT_UPTIME_RANGES
        }
    },
    methods: {
        ...mapActions({
            fetch: 'uptime/fetchGroupsWithServices',
            fetchChartData: 'uptime/fetchChartData',
            setRange: 'uptime/setRange',
            setService: 'uptime/setService',
            pushMessage: 'messages/push'
        }),
        async setChartRange(value) {
            await this.setRange(value)
            this.setChartData()
        },
        setChartData() {
            this.chartData = {
                range: this.range,
                labels: this.chart.labels,
                values: this.chart.values
            }
        },
    },
    async created() {
        try {
            await this.progress(() => this.fetch())
        } catch (e) {
            this.pushMessage(this.$t('communicates.errors.fetch-history'))
        }
        if (this.services.length > 0) {
            this.setService(this.services[0])
        }
        this.setChartData()
        this.isChartDataReady = true
    }
}
</script>
<style lang="scss" scoped>
.uptime {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    overflow: auto;
    box-sizing: border-box;
    min-height: 485px;

    &__header {
        text-align: center;
        font-weight: 500;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1.75rem;
        color: $textColor2;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .charts {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint($lg) {
            flex-direction: row;
        }

        &__average {
            border-radius: 50%;
            width: 165px;
            height: 165px;
            background-color: #eee;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 2.125rem;
            font-weight: 300;
            color: #575757;
            text-align: center;
            white-space: nowrap;

            .label {
                font-size: 1rem;
                text-transform: uppercase;
                padding: 0 16px;
                text-align: center;
                word-wrap: break-word;
            }
        }
    }

    .bar {
        display: flex;
        padding: 8px;
        border-radius: 8px;
        background-color: #F9FAFC;
        justify-content: space-between;

        &__ranges {
            align-self: center;
            display: flex;
            flex-direction: column;

            @include breakpoint($md) {
                flex-direction: row;
            }

            .button {
                margin-right: 32px;
                background-color: transparent;
                box-shadow: none;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.6875rem;
                border-radius: 4px;
                padding: 0 16px;
                border: none;

                &--selected {
                    background-color: $textColor;
                    color: #fff;
                }

                @include breakpoint($sm) {
                    width: 100%;
                }
            }
        }

        &__services {
            .services-select {
                width: 250px;
                height: 36px;
                background-color: #FFF;
                text-transform: none;
            }
        }
    }
}
</style>
