<script>
export default {
    name: 'Tab',
    props: {
        index: {
            type: Number,
            required: true
        },
        tabKey: {
            type: String,
            required: true
        }
    },
    inject: ['register'],
    data () {
        return {
            active: false,
            rendered: false
        }
    },
    mounted () {
        try {
            this.register(this)
        } catch (e) {
            throw new Error('Could not register tab component in parent')
        }
    },
    methods: {
        show () {
            this.active = true
            this.rendered = true
        },
        hide () {
            this.active = false
        }
    },
    expose: ['show', 'hide']
}
</script>

<template>
    <div class="tab" v-show="active" v-if="rendered">
        <slot />
    </div>
</template>
